<template>
  <div class="container">
    <div class="content">
      <div class="title">
        <div class="title-top">
          <img src="../assets/social/home.png"
               class="img-icon"
               alt="">
          <h1 class="title-h1">SOCIAL</h1>
        </div>
        <div class="title-middle">
          <h1 class="title-h1">&RESPONSIBILITY</h1>
        </div>
        <div class="title-bottom">
          社会责任
        </div>
      </div>

      <div class="content-bottom">
        <div class="header">
          <el-row type="flex"
                  justify="center"
                  align="middle">
            <el-col :span="8"
                    class="flex">

              <div class="h-title">使命</div>
              <div class="icon">
                <img src="../assets/social/icon.png"
                     class="img"
                     alt="">

              </div>
            </el-col>
            <el-col :span="8"
                    class="flex">

              <div class="h-title">愿景</div>
              <div class="icon">
                <img src="../assets/social/icon2.png"
                     class="img"
                     alt="">

              </div>
            </el-col>
            <el-col :span="8"
                    class="flex">

              <div class="h-title">价值观</div>
              <div class="icon">
                <img src="../assets/social/icon3.png"
                     class="img"
                     alt="">

              </div>
            </el-col>
          </el-row>
        </div>
        <div class="bottom">
          <el-row type="flex"
                  justify="center"
                  align="middle">
            <el-col :span="8"
                    class="flex">
              <div class="bottom-text">追求全体员工物质和精神幸福，致力医药健康事业，为人类社会发展做出贡献。</div>
            </el-col>
            <el-col :span="8"
                    class="flex">
              <div class="">成为医药健康行业
                世界500强</div>
            </el-col>
            <el-col :span="8"
                    class="flex">
              <div class="">诚信，利他</div>
              <span>创新，拼搏</span>
            </el-col>
          </el-row>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Duty'
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    height: 100%;
    color: #fff;
    padding: 22% 15% 15%;
    background: url("../assets/social/bgc.png") no-repeat;
    background-size: cover;
    box-sizing: border-box;
    .title {
      color: #fff;
      padding-bottom: 20%;
      box-sizing: border-box;
      .img-icon {
        height: 65px;
        padding-right: 6%;
      }
      .title-top,
      .title-middle {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .title-h1 {
        font-size: 70px;
      }
      .title-bottom {
        display: flex;
        justify-content: flex-end;
        font-size: 47px;
        font-weight: bold;
      }
    }
    .content-bottom {
      color: #fff;
      width: 100%;
      .header {
        border-bottom: 2px solid;

        .h-title {
          font-size: 30px;
          font-weight: bold;
          padding-bottom: 15%;
        }
        .icon {
          padding-bottom: 13%;
        }
      }
      .flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .bottom {
        padding-top: 3%;
        font-size: 20px;
        .bottom-text {
          width: 39%;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  // 顶部标题
  .container {
    .content {
      padding: 45% 6%;
      box-sizing: border-box;
      .title {
        padding-bottom: 40%;
        .title-top {
          .img-icon {
            width: 30px;
            height: 30px;
          }
        }
        .title-h1 {
          font-size: 30px !important;
        }
        .title-bottom {
          font-size: 28px;
        }
      }

      .content-bottom {
        color: #fff;
        width: 100%;
        .header {
          border-bottom: 2px solid;

          .title {
            font-size: 25px;
          }
          .icon {
            padding-bottom: 13%;
            .img {
              width: 35px;
              height: 35px;
            }
          }
        }
        .bottom {
          padding-top: 3%;
          .bottom-text {
            width: 100%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    .content {
      padding: 45% 6%;
      box-sizing: border-box;

      .title {
        padding-bottom: 40%;
        .title-h1 {
          font-size: 45px;
        }
        .img-icon {
          width: 50px;
          height: 30px;
        }
      }

      .content-bottom {
        color: #fff;
        width: 100%;
        .header {
          border-bottom: 2px solid;

          .title {
            font-size: 25px;
          }
          .icon {
            padding-bottom: 13%;
            .img {
              width: 35px;
              height: 35px;
            }
          }
        }
        .bottom {
          padding-top: 5%;
          font-size: 13px;
          .bottom-text {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>